import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

import { LocalDataSource } from 'ng2-smart-table';

@Component({
  selector: 'app-filter-table',
  templateUrl: './filter-table.component.html',
  styleUrls: ['./filter-table.component.scss'],
})
export class FilterTableComponent implements OnInit, OnChanges {
  @Input() tableSettings: Array<Object> = [];
  @Input() tableSeedData: Array<Object> = [];
  @Input() label: any;
  newData: any = [];
  // @Input() domotzEnabled: any;

  constructor(private route: ActivatedRoute, private Auth: AuthService, private router: Router) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tableSeedData && this.tableSeedData.length !== 0) {
      this.determineIfDomotzIsEnabledByCompanyId(this.tableSeedData);
    }
  }

  determineIfDomotzIsEnabledByCompanyId = async (obj) => {
    const newArray = await Promise.all(
      obj.map(async (e) => {
        return {
          ...e,
          domotzEnabled: await this.getDomotzAccountByCompanyId(e.company_id),
        };
      })
    );

    this.newData = newArray;
  };

  checkIfDomotzIsEnabled = (dhui, item) => {
    const arr = dhui.split('');
    // console.log(item.iot_devices_domotz);
    
    return item.domotzEnabled ? arr.join('') + item.iot_devices_domotz + '/' + item.spaces_domotz : dhui + '/' + item.spaces;
    // console.log(item.domotzEnabled);
    // return 'hey';
  };

  getDomotzAccountByCompanyId = (company_id) => {
    return new Promise((resolve) => {
      this.Auth.getDomotzAccountByCompanyId(company_id).subscribe((data: any) => {
        const response = data.enabled === 1;
        resolve(response);
      });
    });
  };
}
