import { Component, OnInit, Output, SimpleChanges, ViewChild, ElementRef, EventEmitter, OnDestroy } from '@angular/core';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf'
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { LocalDataSource } from 'ng2-smart-table';
import { scaleRouterOulet } from '../app/services/ScaleRouterOutlet.animation';
import { AuthService } from './services/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TableFilterService } from './services/table-filter.service'
import { RefreshApiService } from './services/refresh-api.service'
import { phone } from 'phone';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [scaleRouterOulet]
})
export class AppComponent {

  // health block states
  HealthBlockStateUpLt = 'home';
  HealthBlockStateUpRt = 'home';
  HealthBlockStateLwLt = 'home';
  HealthBlockStateLwRt = 'home';
  ScaleRouterOutletState = 'hide';
  activeSection: string;
  activeBtn: string;
  public videoSitesSummary;
  public audioDeviceSummary;
  public audioUserSummary;
  public cloudRoomSummary;
  id: number;
  videoDeviceData: LocalDataSource;
  audioDeviceData: LocalDataSource;
  audioUserData: LocalDataSource;
  cloudRoomData: LocalDataSource;
  loginClass: string;
  //checks auth.guard, false shows login(reg screens), true shows four panels and menu.
  authLoggedon: any;//this.Auth.isAuthenticated();
  apiTimers: any;
  videoDeviceApiSummary: any = [];
  videoApiData: any = [];
  updateAPI: any;
  @Output() refreshData: EventEmitter<any> = new EventEmitter();
  videoDeviceSummary: any = [];

  // TUTORIAL RUN
  @Output() runTutorial: any = 0; //set 1 to run and 0 to not

  ciscoDevices: any = 0;
  polycomDevices: any = 0;
  polycomListLength: any = "";
  ciscoListLength: any = "";

  keyTut() {
    localStorage.setItem('runTut', '1');
  }
  animationHome() {
    //timer for background blue color on reg stat buttons
    setTimeout(() => {
      // this.HealthBlockStateUpLt = this.HealthBlockStateUpLt === 'home' ? 'left' : 'home';
      this.HealthBlockStateUpLt = 'home'
    }, 200);
    setTimeout(() => {
      // this.HealthBlockStateUpRt = this.HealthBlockStateUpRt === 'home' ? 'left' : 'home';
      this.HealthBlockStateUpRt = 'home'
    }, 150);
    setTimeout(() => {
      // this.HealthBlockStateLwLt = this.HealthBlockStateLwLt === 'home' ? 'left' : 'home';
      this.HealthBlockStateLwLt = 'home'
    }, 100);
    setTimeout(() => {
      // this.HealthBlockStateLwRt = this.HealthBlockStateLwRt === 'home' ? 'left' : 'home';
      this.HealthBlockStateLwRt = 'home'
    }, 50);
    setTimeout(() => {
      // this.ScaleRouterOutletState = this.ScaleRouterOutletState === 'hide' ? 'show' : 'hide';
      this.ScaleRouterOutletState = 'hide'
    }, 10);
  }
  animationLeft() {
    //timer for background blue color on reg stat buttons
    // this.HealthBlockStateUpLt = this.HealthBlockStateUpLt === 'home' ? 'left' : 'home';
    this.HealthBlockStateUpLt = 'left'
    setTimeout(() => {
      // this.HealthBlockStateUpRt = this.HealthBlockStateUpRt === 'home' ? 'left' : 'home';
      this.HealthBlockStateUpRt = 'left'
    }, 40);
    setTimeout(() => {
      // this.HealthBlockStateLwLt = this.HealthBlockStateLwLt === 'home' ? 'left' : 'home';
      this.HealthBlockStateLwLt = 'left'
    }, 80);
    setTimeout(() => {
      // this.HealthBlockStateLwRt = this.HealthBlockStateLwRt === 'home' ? 'left' : 'home';
      this.HealthBlockStateLwRt = 'left'
    }, 100);
    setTimeout(() => {
      // this.ScaleRouterOutletState = this.ScaleRouterOutletState === 'hide' ? 'show' : 'hide';
      this.ScaleRouterOutletState = 'show'
    }, 120);
  }
  verifiedCode: string = 'false';

  constructor(
    public http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private Auth: AuthService,
    public tableService: TableFilterService,
    public refreshApiData: RefreshApiService
  ) {

    // This recieves the update data boolean to start the timer
    this.verifiedCode = localStorage.getItem('verified');
    this.refreshApiData.refreshAPIListen().subscribe((m: any) => {
      this.refreshData = m;

      // This function gets the API data for the server
      this.getApiData();
    });

    // subscribe to home component messages
    this.router.events.subscribe((event) => {

      if (event instanceof NavigationEnd) {
        let checkUrl = event.url.split(/[\/?]/) // Split by '/' or '?'
        this.verifiedCode = localStorage.getItem('verified');
        const token = localStorage.getItem('token');
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(token);
        if (event.url == "/") {
          if (this.verifiedCode === 'true') {
            this.authLoggedon = this.Auth.isAuthenticated();
            this.animationHome()
            this.activeSection = "";
            this.activeBtn = "";
          } else {
            const phoneLib = phone(localStorage.getItem('phone'), { validateMobilePrefix: true });
            if (decodedToken.two_factor_auth_option === "sms" && phoneLib.isValid) {
              this.router.navigate(['/two-factor-auth']);
            } else {
              this.router.navigate(['/add-phone-number']);
            }
          }
        } else if (event.url === '/two-factor-auth') {
          const phoneLib = phone(localStorage.getItem('phone'), { validateMobilePrefix: true });
          if (this.verifiedCode === 'true') {
            this.router.navigate(['/']);
          } else if (!phoneLib.isValid) {
            this.router.navigate(['/add-phone-number']);
          }
        }
        else if (['adminlogin', 'resetpassword', 'adminnewuserregistration', 'adminforgotpassword', ' `', 'adminresetpassword'].includes(checkUrl[1]) && this.Auth.isAuthenticated()) {
          this.router.navigate(['/']);

        } else {
          this.animationLeft()
          this.authLoggedon = this.Auth.isAuthenticated();
          let a = event.url.split("/");
          this.activeSection = a[1];
          this.activeBtn = a[2];
        }
      }
    });

    this.videoDeviceData = new LocalDataSource();

    this.route.params.subscribe(params => {
      this.id = +params['id'];
    });
  }

  // This function handle pulling the API data from the server every 30 seconds
  getApiData() {
    if (this.refreshData) {
      localStorage.setItem('runTut', '0');

      if (localStorage.getItem('keyDownload') == '1') {
        localStorage.setItem('keyDownload', '1');
      } else {
        localStorage.setItem('keyDownload', '0');
      }
    }
  }

  // This is the API Refresh Data 30 second timer
  getApiDataTimer() {
    //    if (!this.apiTimers) {
    this.apiTimers = setInterval(() => {
      // this.refreshData.emit(true)
      this.refreshApiData.updateApiData(true);
    }, 30000)
    //  }
  }

  ngOnInit() {
    //axerebase 090324
    // redirect route='' on init to login page

    //This function handle wether to route the user to login or home page
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let checkUrl = event.url.split(/[\/?]/) // Split by '/' or '?'
        if (this.Auth.isAuthenticated() != true && !['adminnewuserregistration', 'resetpassword', 'adminforgotpassword', 'adminregistration', "adminresetpassword", "adminlogin"].includes(checkUrl[1])) {
          this.router.navigate(['adminlogin'])
        } else {
          if (this.Auth.isAuthenticated()) {
            clearInterval(this.apiTimers);
            this.getApiDataTimer();
          }
        }
      }
    })

  }

  ngOnDestroy() {
    clearInterval(this.apiTimers);
  }



  @ViewChild('container', { static: false }) container: ElementRef;

  val = 5;
  total = 100;

  clicked() {
    this.val += 10;

  }

  downloadPDF() {

    let element = document.getElementById('rt-panel');
    // var data = element.className;
    // element.className += 'html2canvasreset';

    html2canvas(document.getElementById('rt-panel')).then(function (canvas) {
      // element.className = data;
      var img = canvas.toDataURL("image/png");
      var doc = new jsPDF('l', 'in', 'letter');

      var pdfW = 8.5 * 72;
      var pdfH = 72 * 11;
      doc.addImage(img, 'JPEG', 0, 0, 11, 8);
      doc.save('testCanvas.pdf');
    });
  }

}
