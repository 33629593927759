import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { PopupTypeService } from '../../../services/popupType.service';
import { environment } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../services/auth.service';
import { UserInfoService } from '../../../services/user-info.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyDialogComponent } from '../../../dialog/company-dialog/company-dialog.component';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { JwtHelperService } from '@auth0/angular-jwt';
import { DatePipe } from '@angular/common';
import { FormGroup, AbstractControl, Validators, FormControl, FormBuilder } from '@angular/forms';
import * as moment from 'moment-timezone';
import { DomotzDialogComponent } from '../../../dialog/domotz-dialog/domotz-dialog.component';

@Component({
  selector: 'app-info-site-details',
  templateUrl: './info-site-details.component.html',
  styleUrls: ['./info-site-details.component.scss'],
})
export class InfoSiteDetailsComponent implements OnInit, OnChanges {
  @Input() siteInformation: any;
  @Input() currentUserInfo: any;
  @Input() currentCompanyInfo: any;
  @Input() isPlatformAdmin: any;
  @Input() isTenantAdmin: any;
  @Output() infoSiteEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() mspButtonClicked: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('companyLogoInput', { static: false })
  companyLogoInput: ElementRef;
  loader = environment.config.dynamicImage.loader;
  enable2FAFeature = false;
  isDomotzEnvEnabled = environment.config.domotzIntegration;

  submitted: any = false;
  control: FormControl;
  mspForm: FormGroup;
  server_url: string = environment.config.serverUrl;
  isShowTeleportivity: boolean = environment.config.isShowTeleportivity;
  allOptionsListData = [];
  companyLoginType: string;
  mspEmail: string = '';
  shouldShowIntergrator: boolean;
  companyImageSelected: File = null;
  companyImageSrc: any = this.server_url + '/api/v1/images/companylogo/' + null;
  isShowChangeImageBtns: boolean = false;
  isExtendTrialClicked = 0;
  isExtendSubscriptionClicked = 0;
  trialdate: any = '';
  subscriptiondate: any = '';
  settings = {
    bigBanner: false,
    timePicker: false,
    format: 'dd-MM-yyyy',
    defaultOpen: true,
    closeOnSelect: true,
  };

  newStatusToUpd = '';
  showStatConfirmation = 0;

  nowUpdateCompId = '';
  isStatusUpdated = 0;
  isStatError = 0;
  statErrorMsg = '';
  statSuccessMsg = '';

  showTConfirmation = 0;

  issdateUpdated = 0;
  issdateError = 0;
  sdateErrorMsg = '';
  sdateSuccessMsg = '';

  selCompDtlData = [];

  istdateUpdated = 0;
  istdateError = 0;
  tdateErrorMsg = '';
  tdateSuccessMsg = '';

  nowSelectedStatus = '';
  mspEmailStr = '';

  colorStatus: any = 'green';
  showSConfirmation = 0;
  isCompanyLogoEnabled: boolean = false;

  // webview logic added
  isWebViewAllowed: any;

  isShowEditCompany: boolean = false;

  showDelConfirmation = 0;

  isShowMSPDomotzButton: Boolean = false;

  // iot and single domotz button
  isShowSingleDomotzButton: Boolean = false;
  isShowIOTButton: Boolean = false;
  shouldShowSSOButton: Boolean = environment.config['SSO']?.enabled;

  /**
   * Author: ShahzaibSE
   * Feature: Hiding Instant Login Token 
   */

  isPasswordVisible: boolean = false
  passwordVisibilityTimeout: any

  togglePasswordVisibility(){
    try{
      /// ==== Test Code ==== //
      // if(this.currentCompanyInfo.tenant_admin === 0){
      //   console.log("Admin is not a tenant admin.")
      // }

      // Only allow visibility toggle for ADMIN users
      // if (this.currentUserInfo.accesslevel !== undefined && this.currentUserInfo.accesslevel === 'ADMIN' && this.currentCompanyInfo.tenant_admin !== undefined && this.currentCompanyInfo.tenant_admin !== 1) {
      
      // ------ Code to test for tenant_admin ------ //
      // this.currentCompanyInfo.tenant_admin = 1 // Change value to test for tenant admin.
      // ------ //

      // Current Check: Verify Admin is a tenant admin.
      if(this.currentCompanyInfo.tenant_admin === 1){
        this.isPasswordVisible = !this.isPasswordVisible
        // Clear any previous timeout if it exists
        if (this.passwordVisibilityTimeout) {
          clearTimeout(this.passwordVisibilityTimeout);
        }

        // If the password is made visible, set a timeout to hide it after 30 seconds
        if (this.isPasswordVisible) {
          this.passwordVisibilityTimeout = setTimeout(() => {
            this.isPasswordVisible = false;
            console.log('Password hidden automatically after 30 seconds.');
          }, 30000); // 30 seconds
        }
    }else {
      console.log("Admin is not a tenant admin")
    }
    }catch(err){
      throw err
    }
  }

  // --- //

  get f() {
    return this.mspForm.controls;
  }

  constructor(
    public ds: PopupTypeService,
    public http: HttpClient,
    private Auth: AuthService,
    private formBuilder: FormBuilder,
    private fb: FormBuilder,
    public datepipe: DatePipe,
    private userInfo: UserInfoService,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.control = fb.control({ value: 'my val', disabled: true });
    this.enable2FAFeature = true; //environment.config.enable2FAFeature;
    // this.getInstantLoginToken()
  }

  commaSepEmail = (control: AbstractControl): { [key: string]: any } | null => {
    const emails = control.value.split(';').map((e) => e.trim());
    const forbidden = emails.some((email) => Validators.email(new FormControl(email)));
    return forbidden ? { 'toAddress': { value: control.value } } : null;
  };

  checkForm() {
    this.mspForm = this.formBuilder.group({
      mspemail: [this.mspEmail, [Validators.nullValidator, this.commaSepEmail]],
    });
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   if (changes.videoDeviceData && this.videoDeviceData) {
  //     this.setData();
  //   }
  // }

  instantLoginToken = '';
  enableInstantLogin = 0;

  ngOnChanges(changes: SimpleChanges) {
    this.companyImageSrc = this.server_url + '/api/v1/images/companylogo/' + null;
    // setTimeout(()=>{
    //   this.getInstantLoginToken()
    // },4000)
    this.getInstantLoginToken()
    // this.isShowEditCompany = false;
    if (changes.siteInformation && this.siteInformation) {
      if (this.siteInformation[0]) {
        this.companyImageSelected = null;
        this.companyImageSrc =
          this.server_url + '/api/v1/images/companylogo/' + this.siteInformation[0].logo;
        this.nowUpdateCompId = this.siteInformation[0].company_id;
        this.shouldShowIntergrator =
          this.companyLoginType !== 'superAdmin' && this.siteInformation[0].is_integrator === 1;
        if (this.companyLoginType === 'superAdmin') {
          this.shouldShowIntergrator = true;
        }

        this.checkMSPDomotzButton(this.siteInformation[0].is_integrator);
        // this.instantLoginToken = this.siteInformation[0]?.instantLoginToken || '';
        this.instantLoginToken = this.siteInformation[0]?.instantLoginToken || '' ? '' : null;
        this.enableInstantLogin = this.siteInformation[0]?.enableInstantLogin || 0;
      }

      this.setOtherSetting();
      this.setIotSetting();
    }
  }

  generateToken(length = 32): string {
    const array = new Uint8Array(length / 2);
    window.crypto.getRandomValues(array);
    return Array.from(array, (byte) => ('0' + byte.toString(16)).slice(-2)).join('');
  }

  getInstantLoginToken() {
    try{
      let company_id: number = this.currentCompanyInfo.company_id !== undefined ? this.currentCompanyInfo.company_id : 0
      let email: string = this.currentUserInfo.email !== undefined ? this.currentUserInfo.email : ''
      let APIKey: string = this.currentCompanyInfo.APIKey !== undefined ? this.currentCompanyInfo.APIKey : ''
      // console.log("Company ID, Email, and API Key - Info Site Details Component: ", company_id, email, APIKey)
      this.Auth.getInstantLoginToken(company_id, email, APIKey).subscribe( 
        (response) => {
          this.instantLoginToken = response.instantLoginToken;
          // console.log("Response Instant Login Token API - Info Site Detail Component:", response)
          // console.log("Instant Login Token - Info Site Detail Component: " + this.instantLoginToken)
          // console.log('Token:', this.instantLoginToken);
          this.isPasswordVisible = false
      },
      (error) => {
        console.error('Error fetching token:', error);
      })
    }catch(err){
      throw err
    }
  }

  clickGenInstantLoginToken() {
    this.instantLoginToken = this.generateToken();
    this.Auth.updateInstantLoginToken(this.instantLoginToken, +this.nowUpdateCompId).subscribe();
  }

  ngOnInit() {
    this.setDefaultDate();
    this.isShowEditCompany = false;
    /**
     * Author: ShahzaibSE
     * This will hide token after 30 seconds.
     */
    // setTimeout(()=>{
    //   this.getInstantLoginToken()
    // },5000)
    this.passwordVisibilityTimeout = setTimeout(() => {
      this.isPasswordVisible = false;
    }, 30000);
  }

  checkMSPDomotzButton(integratorStatus) {
    if (this.isDomotzEnvEnabled) {
      // if (this.isTenantAdmin || this.isPlatformAdmin || integratorStatus === 1) {
      //   this.isShowMSPDomotzButton = true;
      // } else {
      //   this.isShowMSPDomotzButton = false;
      // }
      this.isShowMSPDomotzButton = true;
    } else {
      this.isShowMSPDomotzButton = false;
    }
  }

  setIotSetting = () => {
    if (
      this.siteInformation[0] &&
      this.siteInformation[0].is_msp_domotz == 0 &&
      (this.isTenantAdmin || this.isPlatformAdmin)
    ) {
      this.isShowIOTButton = true;
    } else {
      this.isShowIOTButton = false;
    }
    if (
      this.siteInformation[0] &&
      this.siteInformation[0].is_msp_domotz == 0 &&
      (this.isTenantAdmin || this.isPlatformAdmin)
    ) {
      this.isShowSingleDomotzButton = true;
    } else {
      this.isShowSingleDomotzButton = false;
    }
  };

  setOtherSetting = () => {
    this.setChoosenStatus();
    this.cancelUpd();
    this.mspEmail = '';
    if (this.siteInformation[0] && this.siteInformation[0].msp_email) {
      this.mspEmail = this.siteInformation[0].msp_email.replace(/,/g, ';');
    }
    this.checkForm();
    this.submitted = false;
    this.isShowInstruction = false;
    this.showDelConfirmation = 0;
    this.isShowMSPinfo = 0;
    if (this.currentUserInfo) {
      this.isAccessLevelAdmin();
    }
  };

  setDefaultDate = () => {
    this.companyLoginType = this.userInfo.getInfo().loginType;
    this.ds.sendPopupType(0);

    let arrtype = [
      { status: 'Trial', value: 'TRIAL' },
      { status: 'Subscription', value: 'SUBSCRIPTION' },
      { status: 'Inactive', value: 'INACTIVE' },
    ];

    for (let s in arrtype) {
      this.allOptionsListData.push(arrtype[s]);
    }

    if (this.siteInformation[0] && this.siteInformation[0].msp_email) {
      this.mspEmail = this.siteInformation[0].msp_email.replace(/,/g, ';');
    }

    this.checkForm();
    this.isShowInstruction = false;
    // trialdate: any = '';
    // subscriptiondate: any = '';
    const newDate = moment().format().split('T')[0];
    this.subscriptiondate = newDate;
    this.trialdate = newDate;
  };

  // JwtHelperService;

  sendPopupType(p) {
    this.ds.sendPopupType(p);
  }

  ngOnDestroy() {
    // clear message
    this.ds.clearPopupType();
  }

  async delay(ms: number) {
    // await new Promise((resolve) => setTimeout(() => resolve(), ms)).then(() => { });
    //check this
  }

  cancelUpd() {
    this.showSConfirmation = 0;
    this.showStatConfirmation = 0;
    this.showTConfirmation = 0;
    this.isExtendTrialClicked = 0;
    this.isExtendSubscriptionClicked = 0;
    this.newStatusToUpd = this.nowSelectedStatus;
    this.trialdate = new Date();
  }

  changeStatus(newStatus) {
    this.reset();
    this.showStatConfirmation = 1;
    this.newStatusToUpd = newStatus;
  }

  setStatus() {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const compStat = {
      company_id: this.nowUpdateCompId,
      status: this.newStatusToUpd,
    };

    this.Auth.updateStatus(compStat).subscribe((data) => {
      if (data.response[0].status === 'OK') {
        this.statSuccessMsg = data.response[0].message;
        this.isStatusUpdated = 1;
        this.showStatConfirmation = 0;
        this.isStatusUpdated = 0;
        this.statSuccessMsg = '';
        this.fetchCompanyInfo();
      } else {
        if (data.response[0].status === 'Error') {
          this.statErrorMsg = data.response[0].message;
          this.isStatError = 1;
          this.showStatConfirmation = 0;
          this.delay(3000).then(() => {
            this.isStatError = 0;
            this.statErrorMsg = '';
          });
        }
      }
    });
  }

  updTdate(tmptdate) {
    let newtdate = this.datepipe.transform(tmptdate, 'yyyy-MM-dd');
    this.updateExtendTrial(newtdate);
  }

  updatedSubscriptionDate = () => {
    this.updateExtendSubscription(this.subscriptiondate);
  };

  updateExtendTrial(tdate) {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const compTDateUpd = {
      company_id: this.nowUpdateCompId,
      date: tdate,
    };

    this.Auth.extendTrialDate(compTDateUpd).subscribe((data) => {
      if (data.response[0].status === 'OK') {
        this.tdateSuccessMsg = data.response[0].message;
        this.istdateUpdated = 1;
        this.showTConfirmation = 0;
        this.delay(3000).then(() => {
          this.istdateUpdated = 0;
          this.tdateSuccessMsg = '';
          this.isExtendTrialClicked = 0;
          this.fetchCompanyInfo();
        });
      } else {
        if (data.response[0].status === 'Error') {
          this.tdateErrorMsg = data.response[0].message;
          this.istdateError = 1;
          this.showTConfirmation = 0;
          this.delay(3000).then(() => {
            this.istdateError = 0;
            this.tdateErrorMsg = '';
            this.isExtendTrialClicked = 0;
          });
        }
      }
    });
  }

  updateExtendSubscription(sdate) {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const compSDateUpd = {
      company_id: this.nowUpdateCompId,
      date: sdate,
    };

    this.Auth.extendSubscriptionDate(compSDateUpd).subscribe((data) => {
      if (data.response[0].status === 'OK') {
        this.sdateSuccessMsg = data.response[0].message;
        this.issdateUpdated = 1;
        this.showSConfirmation = 0;

        this.issdateUpdated = 0;
        this.sdateSuccessMsg = '';
        this.isExtendSubscriptionClicked = 0;
        this.fetchCompanyInfo();
      } else {
        if (data.response[0].status === 'Error') {
          this.sdateErrorMsg = data.response[0].message;
          this.issdateError = 1;
          this.showSConfirmation = 0;
          this.delay(3000).then(() => {
            this.issdateError = 0;
            this.sdateErrorMsg = '';
            this.isExtendSubscriptionClicked = 0;
          });
        }
      }
    });
  }

  fetchCompanyInfo() {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const compId = {
      company_id: this.nowUpdateCompId,
    };

    this.selCompDtlData = [];
    this.Auth.getCompanyInfo(compId).subscribe((data) => {
      this.selCompDtlData = data['response'];
      this.siteInformation = this.selCompDtlData;
      this.setChoosenStatus();
    });
  }

  setChoosenStatus() {
    if (this.siteInformation[0] && this.siteInformation[0].status == 'TRIAL') {
      this.nowSelectedStatus = 'TRIAL';
    } else if (this.siteInformation[0] && this.siteInformation[0].status == 'SUBSCRIPTION') {
      this.nowSelectedStatus = 'SUBSCRIPTION';
    } else if (this.siteInformation[0] && this.siteInformation[0].status == 'INACTIVE') {
      this.nowSelectedStatus = 'INACTIVE';
    }
    if (this.siteInformation[0] && this.siteInformation[0].msp_email)
      this.mspEmailStr = this.siteInformation[0].msp_email.replace(',', ';');
  }

  toggleWebView($event) {
    this.isWebViewAllowed = this.siteInformation[0].web_view == 1 ? 0 : 1;
    this.siteInformation[0].web_view = this.isWebViewAllowed;
    const compWebView = {
      company_id: this.nowUpdateCompId,
      web_view: this.isWebViewAllowed,
    };

    this.Auth.updateCmpWebView(compWebView).subscribe((data) => {
      if (data['status'] == 200) {
      }
    });
  }

  // webview logic added
  isMspAllowed: any = false;
  isShowInstruction: any = false;
  toggleMsp($event) {
    this.isMspAllowed = !this.isMspAllowed;
    this.isShowInstruction = false;
    if (this.isMspAllowed == 1 && this.mspEmail == '') {
      this.isMspAllowed = !this.isMspAllowed;
      this.isShowMSPinfo = true;
      this.isShowInstruction = true;
      (<HTMLInputElement>document.getElementById('msp-toggle')).checked = this.isMspAllowed;

      return;
    }

    const compMsp = {
      company_id: this.nowUpdateCompId,
      msp_notification: this.isMspAllowed,
    };

    this.Auth.updateCmpMspNotification(compMsp).subscribe((data) => {
      if (data['status'] == 200) {
        (<HTMLInputElement>document.getElementById('msp-toggle')).checked = this.isMspAllowed;
        this.isShowInstruction = false;
      }
    });
  }

  toggleSpaces($event) {
    const spacesValue = $event.currentTarget.checked;
    const spacesData = {
      company_id: this.nowUpdateCompId,
      team_space_status: spacesValue ? '1' : '0',
    };

    this.Auth.updateTeamSpaces(spacesData).subscribe((data) => {
      this.infoSiteEventEmitter.emit('refreshCompanyInfo');
    });
  }

  onToggleMSPDomotz(event) {
    event.preventDefault();
    this.showDomotzWarnDialog();
  }

  showDomotzWarnDialog() {
    const config = {};
    const domotzDialog = this.dialog.open(DomotzDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        config,
      },
    });

    domotzDialog.afterClosed().subscribe((data) => {
      if (data) {
        this.onConfirmToToggleMSPDomotz();
      }
    });
  }

  onConfirmToToggleMSPDomotz() {
    const httpObservable = this.siteInformation[0].is_msp_domotz
      ? this.Auth.deleteDomotzAccount.bind(this, this.nowUpdateCompId)
      : this.Auth.deleteDomotzAccountOfAllChildren.bind(this, this.nowUpdateCompId);
    httpObservable().subscribe(() => {
      this.toggleMSPDomotz();
    });
  }

  toggleMSPDomotz() {
    this.mspButtonClicked.emit();
    const mspDomotzData = {
      company_id: this.nowUpdateCompId,
      status: this.siteInformation[0].is_msp_domotz === 1 ? '0' : '1',
    };

    this.Auth.updateMSPDomotz(mspDomotzData).subscribe((data) => {
      this.siteInformation[0].is_msp_domotz = this.siteInformation[0].is_msp_domotz === 1 ? 0 : 1;
      this.infoSiteEventEmitter.emit('refreshCompanyInfo');
    });
  }

  // toggleMSPDomotz($event) {
  //   this.mspButtonClicked.emit();
  //   const mspDomotzValue = $event.currentTarget.checked;
  //   const mspDomotzData = {
  //     company_id: this.nowUpdateCompId,
  //     status: mspDomotzValue ? "1" : "0",
  //   };

  //   this.Auth.updateMSPDomotz(mspDomotzData).subscribe((data) => {
  //     this.infoSiteEventEmitter.emit('refreshCompanyInfo');
  //   });
  // }

  toggleSingleDomotz($event) {
    this.mspButtonClicked.emit();
    const singleDomotzValue = $event.currentTarget.checked;
    const singleDomotzData = {
      company_id: this.nowUpdateCompId,
      status: singleDomotzValue ? '1' : '0',
    };

    this.Auth.updateSingleDomotz(singleDomotzData).subscribe((data) => {
      this.infoSiteEventEmitter.emit('refreshCompanyInfo');
    });
  }
  isShowMSPinfo: any = 0;
  showMspInfo() {
    this.isShowMSPinfo = !this.isShowMSPinfo;
    this.submitted = false;
    this.isShowInstruction = false;
    this.checkForm();
  }

  saveMsp() {
    //Api call to save the msp email list.

    var mspEmailarr = (<HTMLInputElement>document.getElementById('mspemail')).value.split(';');
    var filteredMspEmailarr = mspEmailarr.filter(function (el) {
      return el != '';
    });

    var mspEmailList = filteredMspEmailarr.toString();

    const compMspEmail = {
      company_id: this.nowUpdateCompId,
      msp_email: mspEmailList,
    };

    this.Auth.updatecmpMspEmail(compMspEmail).subscribe((data) => {
      if (data['status'] == 200) {
        this.isShowMSPinfo = false;
        this.mspEmail = (<HTMLInputElement>document.getElementById('mspemail')).value;
        this.isShowInstruction = false;

        if (mspEmailList == '' && this.isMspAllowed == 1) {
          this.toggleMsp(1);
        }
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.mspForm.invalid) {
      return;
    } else {
      this.saveMsp();
      this.submitted = false;
    }
  }

  cancelDel() {
    this.showDelConfirmation = 0;
  }

  intergratorFunc = (e) => {
    let siteId = this.siteInformation[0] ? this.siteInformation[0].company_id : '';
    let sliderValue = e.target.checked ? 1 : 0;
    let setIntegratorData = {
      'company_id': siteId,
      'value': sliderValue,
    };
    this.Auth.setIntegrator(setIntegratorData).subscribe((data) => {
      this.infoSiteEventEmitter.emit('refreshCompanyInfo');
    });
  };

  twoAuthFunc = (e) => {
    let siteId = this.siteInformation[0] ? this.siteInformation[0].company_id : '';
    let sliderValue = e.target.checked ? 1 : 0;
    //When turning on or off always set to default which is the email.
    let option = 'email';
    const data = {
      company_id: siteId,
      enable_auth: sliderValue,
      twofa_option: option,
    };

    this.Auth.updateTwoFactorAuth(data).subscribe((data) => {
      this.infoSiteEventEmitter.emit('refreshCompanyInfo');
    });
  };

  changeTwoAuthOption = (e) => {
    let siteId = this.siteInformation[0] ? this.siteInformation[0].company_id : '';
    const sliderValue = 1;
    const option = e.target.value;
    const data = {
      company_id: siteId,
      enable_auth: sliderValue,
      twofa_option: option,
    };

    this.Auth.updateTwoFactorAuth(data).subscribe((data) => {
      this.infoSiteEventEmitter.emit('refreshCompanyInfo');
    });
  };

  setTeleportivity = (e) => {
    const isDirectAccess = !this.siteInformation[0].is_direct_access;
    const companyId = this.siteInformation[0] ? this.siteInformation[0].company_id : '';

    let params = {
      'company_id': companyId,
      'value': isDirectAccess ? 1 : 0,
    };

    this.Auth.setDirectAccess(params).subscribe((data) => {
      this.infoSiteEventEmitter.emit('refreshCompanyInfo');
    });
  };

  handlerDate = () => {
    this.showSConfirmation = 1;
  };

  handlerDateTrial = () => {
    this.showTConfirmation = 1;
  };

  onCompanyImageSelected(event) {
    this.companyImageSelected = <File>event.target.files[0];

    if (this.companyImageSelected !== null) {
      this.isShowChangeImageBtns = true;
    }
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = (e) => (this.companyImageSrc = reader.result);

      reader.readAsDataURL(file);
    }
  }

  onCompanyImageCancel() {
    this.isShowChangeImageBtns = false;
    this.companyImageSelected = null;
    this.companyImageSrc =
      this.server_url + '/api/v1/images/companylogo/' + this.siteInformation[0].logo;
    this.companyLogoInput.nativeElement.value = null;
  }

  onCompanyImageSave() {
    this.Auth.updateCompanyLogo(
      this.siteInformation[0].company_id,
      this.companyImageSelected
    ).subscribe((data) => {
      this.isShowChangeImageBtns = false;
    });
  }

  showExtendSubscription = () => {
    this.reset();
    this.isExtendSubscriptionClicked = 1;
    this.showSConfirmation = 1;
  };

  showExtendTrial = () => {
    this.reset();
    this.isExtendTrialClicked = 1;
    this.showTConfirmation = 1;
  };

  reset = () => {
    this.isExtendTrialClicked = 0;
    this.showTConfirmation = 0;
    this.isExtendSubscriptionClicked = 0;
    this.showSConfirmation = 0;
    this.showStatConfirmation = 0;
  };

  editCompany() {
    this.isShowEditCompany = true;
  }

  isAccessLevelAdmin() {
    if (this.currentUserInfo.accesslevel === 'ADMIN') {
      this.isCompanyLogoEnabled = true;
      console.log("this.currentCompanyInfo - Info Site Details Component:", this.currentCompanyInfo)
    }
  }

  showDeleteModal = () => {
    const company_id = this.nowUpdateCompId;

    const config = { company_id };
    const webportal = this.dialog.open(CompanyDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        config,
        infoSiteEventEmitter: this.infoSiteEventEmitter,
        company_name: this.siteInformation[0].company_name,
      },
    });

    // this.removeCompany(eventobj);
  };

  infoSiteEmitter = (event) => {
    this.isShowEditCompany = false;
  };

  gamEnabed: any = true;

  isGamEnabled = () => {
    this.Auth.isGamEnabled().subscribe(
      (data: any) => {
        console.log('isGamEnabled: ', data);
        this.gamEnabed = data.uses_gam;
      },
      (err) => {
        console.log('here');
        // this.gamEnabed = false;
      }
    );
  };

  setGame = ($event) => {
    const flag = $event.currentTarget.checked;

    const formatFlag = flag ? '1' : '0';

    this.Auth.setGamEnabled(formatFlag, this.siteInformation[0].company_id).subscribe(
      (data: any) => {
        if (data) {
          console.log('its a sucess');
          this.infoSiteEventEmitter.emit('refreshCompanyInfo');
        }
      },
      (err) => {
        console.log('here');
        // this.gamEnabed = false;
      }
    );
  };

  setEnableInstantLogin = ($event) => {
    const flag = $event.currentTarget.checked ? 1 : 0;

    if (flag === 1) {
      /** 
       * Write some logic upon enabling instant login.
       */
      // console.log("It's enabled");
      // this.clickGenInstantLoginToken(); // This generates token on enabling Instant Login.
    }

    this.Auth.setEnableInstantLogin(flag, this.siteInformation[0].company_id).subscribe(() => {
      this.infoSiteEventEmitter.emit('refreshCompanyInfo');
    });
  };
}
