import apiServicePathBuilder from './apiServicePathBuilder';

export const environment = {
  production: true,
  dev_server: false,
  apiServicePathBuilder: apiServicePathBuilder({
    apiUrl: 'https://cxdetect-api.visibility.one',
  }),
  config: {
    tenantName: 'CxDetect',
    serverUrl: 'https://fe-service.cxdetect.net',
    mainUrl: 'https://dashboard.cxdetect.net',
    webhookUrl: 'https://iot-webhooks.cxdetect.net/',
    showPoweredBy: false,
    twilioServiceId: "VA0984ed7bcdf80fb06fbdeb7b1f21c130",
    enable2FAFeature: false,
    domotzIntegration: true,
    isShowTeleportivity: true,
    superAdminCompanyId: 36,
    links: {
      terms: 'https://www.cxunify.com/termsandconditions',
    },
    dynamicImage: {
      loginImage: '../assets/img/ctx_img/loginImage.png',
      dashboardLogo: '../assets/img/ctx_img/dashboardLogo.png',
      bgImage: '/assets/img/ctx_img/bgImage.jpg',
      loader: '../assets/img/ctx_img/loader.gif',
      collectorImg: '../assets/img/ctx_img/collector_icon.png',
      macCollector: '../assets/img/ctx_img/collector_icon.png',
      pluginImg: '../assets/img/ctx_img/collector_icon.png',
      desktopPluginImg: '../assets/img/ctx_img/collector_icon.png',
    },
    dynamicText: {
      dTerms: 'Dashboard terms and conditions',
      dLoginTitle: 'MSP',
      dashboardHeader: 'Master Dashboard',
      dCollector: 'Bridge',
      dPlugin: 'Monitor',
      desktopPlugin: 'Desktop Monitor',
    },
    SSO: {
      enabled: true,
      GAMBaseUri: 'https://app.cxunify.com/oauth/gam',
      clientId: 'AxUrzZ0YDV0akiTezuWDofWNYyIZWvhI6ZF52ap9',
      clientSecret: 'admin',
      redirectURI: 'https://admin.cxdetect.net/adminlogin',
      // clientId: 'Y5lzEYVqnl1AbGRR1GdZnIjBYTpp87JSLD4YBojK',
      // redirectURI: 'https://cxhome.cxunify.com',
    },
  },
};
