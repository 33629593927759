<!-- this thing need to be fix T_T -->
<div class="card" class="info-site-details-container">
  <div *ngIf="!isShowEditCompany">
    <div class="row" style="padding-top: 20px; height: 10px;">
      <h4 style="word-break: break-all; margin-left: 20px; height: 20px; font-weight: bold;">
        {{ siteInformation[0]?.company_name }}
      </h4>
    </div>



    <div class="card-body row" sytle="padding-bottom: 0px;">
      <div class="col-4 company-image-container">
        <button *ngIf="isShowChangeImageBtns" (click)="onCompanyImageSave()" type="button" class="btn btn-primary">
          Save Image
        </button>
        <button *ngIf="isShowChangeImageBtns" (click)="onCompanyImageCancel()" type="button" class="btn btn-danger">
          Cancel
        </button>
        <div class="row">
          <input #companyLogoInput type="file" accept="image/jpeg, image/png" (change)="onCompanyImageSelected($event)"
            alt="Click to change company logo." title="Click to change company logo."
            [attr.disabled]="isCompanyLogoEnabled? null: '' " />
          <img [src]="companyImageSrc" />
        </div>
      </div>

      <div class="col-4">
        <div class="row pt-3 px-3" style="font-size: 16px;" *ngIf="siteInformation[0]?.status == 'TRIAL'">
          Trial Start Date: {{ siteInformation[0]?.trial_info.start_date | date: 'MM/dd/yyyy' }}
        </div>
        <div class="row pt-3 px-3" style="font-size: 16px;" *ngIf="siteInformation[0]?.status == 'TRIAL'">
          Trial End Date: {{ siteInformation[0]?.trial_info.end_date | date: 'MM/dd/yyyy' }}
        </div>

        <div class="row pt-3 px-3" style="font-size: 16px;" *ngIf="siteInformation[0]?.status == 'SUBSCRIPTION'">
          Subscription Start Date:
          {{ siteInformation[0]?.subscription_info.start_date | date: 'MM/dd/yyyy' }}
        </div>
        <app-update-subscription-date [companyId]="siteInformation[0]?.company_id"
          [subscriptionInfo]="siteInformation[0]?.subscription_info" [loginType]="this.companyLoginType"
          [refreshCompany]="refreshCompanyListChange" [currentUserInfo]="currentUserInfo">

        </app-update-subscription-date>
        <div class="row pt-3 px-3" style="font-size: 16px;" *ngIf="siteInformation[0]?.status == 'SUBSCRIPTION'">
          Subscription End Date:
          {{ siteInformation[0]?.subscription_info.end_date | date: 'MM/dd/yyyy' }}
        </div>

        <div class="row pt-3 px-3" style="font-size: 16px;" *ngIf="siteInformation[0]?.status == 'INACTIVE'">
          Inactive Start Date:
          {{ siteInformation[0]?.inactive_info.start_date | date: 'MM/dd/yyyy' }}
        </div>
      </div>

      <div *ngIf="currentUserInfo?.accesslevel == 'ADMIN'" class="col-4">
        <select (change)="changeStatus($event.target.value)" id="index" class="form-control"
          [ngModel]="nowSelectedStatus" required>
          <option *ngFor="let options of allOptionsListData" value="{{ options.value }}">{{
            options.status
            }}</option>
        </select>
        <div class="form-group" style="width: inherit;" *ngIf="siteInformation[0]?.status == 'TRIAL'">
          <button type="button" style="width: inherit;" class="btn btn-primary float-right" (click)="showExtendTrial()">
            Extend Trial?
          </button>
        </div>
        <div class="form-group" style="width: inherit;" *ngIf="siteInformation[0]?.status == 'SUBSCRIPTION'">
          <button type="button" style="width: inherit;" class="btn btn-primary float-right"
            (click)="showExtendSubscription()">
            Extend Subscription?
          </button>
        </div>
        <div class="form-group" style="width: inherit;" *ngIf="isExtendTrialClicked == 1">
          <input type="date" class="form-control" id="schedule-date" (change)="handlerDateTrial(event)"
            [(ngModel)]="trialdate" />
        </div>

        <div class="form-group" style="width: inherit;" *ngIf="isExtendTrialClicked == 1">
          <input type="date" class="form-control" id="schedule-date" (change)="handlerDateTrial(event)"
            [(ngModel)]="trialdate" />
        </div>

        <div class="form-group" style="width: inherit;" *ngIf="isExtendSubscriptionClicked == 1">
          <input type="date" class="form-control" id="schedule-date" (change)="handlerDate(event)"
            [(ngModel)]="subscriptiondate" />
        </div>

        <!-- AXE -->
        <div class="info-site-slider-container">
          <div class="info-site-slider-title web-view">
            Web View

          </div>
          <div class="info-site-slider-child">
            <span class="info-site-slider-child-disabled">disabled</span>
            <label class="switch">
              <input type="checkbox" (change)="toggleWebView($event)" [checked]="siteInformation[0]?.web_view == 1" />
              <span class="slider round" id="web-view-toggle"></span>
            </label>
            <span class="info-site-slider-child-enabled">enabled</span>
          </div>

          <div class="info-site-slider-title">MSP Notifications</div>
          <div class="info-site-slider-child">
            <span class="info-site-slider-child-disabled">disabled</span>
            <label class="switch">
              <input type="checkbox" id="msp-toggle" type="checkbox" (change)="toggleMsp($event)"
                [checked]="siteInformation[0]?.msp_notification == 1" />
              <span class="slider round" id="web-view-toggle"></span>
            </label>
            <span class="info-site-slider-child-enabled">enabled</span>
          </div>

          <div *ngIf="isShowTeleportivity" class="info-site-slider-title">Teleportivity</div>
          <div *ngIf="isShowTeleportivity" class="info-site-slider-child">
            <span class="info-site-slider-child-disabled">disabled</span>
            <label class="switch">
              <input type="checkbox" (change)="setTeleportivity($event)"
                [checked]="siteInformation[0]?.is_direct_access === 1" />
              <span class="slider round"></span>
            </label>
            <span class="info-site-slider-child-enabled">enabled</span>
          </div>

          <div *ngIf="shouldShowIntergrator" class="info-site-slider-title">Integrator</div>
          <div *ngIf="shouldShowIntergrator" class="info-site-slider-child">
            <span class="info-site-slider-child-disabled">disabled</span>
            <label class="switch" [ngClass]="{
                'info-site-slider-child':
                  siteInformation[0]?.company_parent !== null || companyLoginType === 'integrator'
              }">
              <input type="checkbox" (change)="intergratorFunc($event)"
                [checked]="siteInformation[0]?.is_integrator === 1" [disabled]="
                  siteInformation[0]?.company_parent !== null || companyLoginType === 'integrator'
                " />
              <span class="slider round"></span>
            </label>
            <span class="info-site-slider-child-enabled">enabled</span>
          </div>

          <div class="info-site-slider-title">Teams Spaces</div>
          <div class="info-site-slider-child">
            <span class="info-site-slider-child-disabled">disabled</span>
            <label class="switch">
              <input type="checkbox" id="spaces-toggle" type="checkbox" (change)="toggleSpaces($event)"
                [checked]="siteInformation[0]?.is_teams_space == 1" />
              <span class="slider round" id="web-view-toggle"></span>
            </label>
            <span class="info-site-slider-child-enabled">enabled</span>
          </div>

          <div *ngIf="isShowMSPDomotzButton" class="info-site-slider-title">MSP Domotz</div>
          <div *ngIf="isShowMSPDomotzButton" class="info-site-slider-child">
            <span class="info-site-slider-child-disabled">disabled</span>
            <!-- <label class="switch" [ngClass]="{
              'info-site-slider-child': !this.isTenantAdmin && !this.isPlatformAdmin
            }">
            <input type="checkbox" name="domotz-toggle"
            [checked]="siteInformation[0]?.is_msp_domotz === 1? true:false" (click)="onToggleMSPDomotz($event)" 
            [disabled]="!this.isTenantAdmin && !this.isPlatformAdmin" /> -->
            <label class="switch" [ngClass]="{
              'info-site-slider-child': siteInformation[0]?.company_parent !== null || companyLoginType === 'integrator'
            }">
              <input type="checkbox" name="domotz-toggle"
                [checked]="siteInformation[0]?.is_msp_domotz === 1? true:false" (click)="onToggleMSPDomotz($event)"
                [disabled]="siteInformation[0]?.company_parent !== null || companyLoginType === 'integrator'" />
              <span class="slider round" id="web-view-toggle"></span>
            </label>
            <span class="info-site-slider-child-enabled">enabled</span>
          </div>

          <!-- Single DOMOTOZ -->
          <!-- <div *ngIf="isShowSingleDomotzButton" class="info-site-slider-title">Single Domotz</div>
          <div *ngIf="isShowSingleDomotzButton" class="info-site-slider-child">
            <span class="info-site-slider-child-disabled">disabled</span>
            <label class="switch">
              <input type="checkbox" id="domotz-toggle" type="checkbox" (change)="toggleSingleDomotz($event)"
                [checked]="siteInformation[0]?.is_single_domotz == 1" />
              <span class="slider round" id="web-view-toggle"></span>
            </label>
            <span class="info-site-slider-child-enabled">enabled</span>
          </div> -->

          <!-- Single Domotoz Ends -->

          <ng-container *ngIf="shouldShowSSOButton">
            <div class="info-site-slider-title">SSO</div>
            <div class="info-site-slider-child">
              <span class="info-site-slider-child-disabled">disabled</span>
              <label class="switch" [ngClass]="{
                'info-site-slider-child': siteInformation[0]?.company_parent !== null || companyLoginType === 'integrator'
              }">
                <input type="checkbox" id="spaces-toggle" type="checkbox" (change)="setGame($event)"
                  [checked]="siteInformation[0]?.uses_gam == 1"
                  [disabled]="siteInformation[0]?.company_parent !== null || companyLoginType === 'integrator'" />
                <span class="slider round" id="web-view-toggle"></span>
              </label>
              <span class="info-site-slider-child-enabled">enabled</span>
            </div>
          </ng-container>

          <ng-container *ngIf="currentUserInfo.accesslevel === 'ADMIN' && !!isPlatformAdmin">
            <div class="info-site-slider-title">Instant Login</div>
            <div class="info-site-slider-child">
              <span class="info-site-slider-child-disabled">disabled</span>
              <label class="switch" [ngClass]='info-site-slider-child'>
                <input type="checkbox" (change)="setEnableInstantLogin($event)" [checked]="!!enableInstantLogin" />
                <span class="slider round" id="web-view-toggle"></span>
              </label>
              <span class="info-site-slider-child-enabled">enabled</span>
            </div>
          </ng-container>

          <div class="info-site-slider-title" [style.visibility]="(enable2FAFeature == false) ? 'hidden' : 'visible' ">
            2FA</div>
          <div class="info-site-slider-child" [style.visibility]="(enable2FAFeature == false) ? 'hidden' : 'visible' ">
            <span class="info-site-slider-child-disabled">disabled</span>
            <label class="switch" [ngClass]='info-site-slider-child'>
              <input type="checkbox" (change)="twoAuthFunc($event)"
                [checked]="siteInformation[0]?.enable_two_factor_auth === 1" />
              <span class="slider round"></span>
            </label>
            <span class="info-site-slider-child-enabled">enabled</span>
          </div>

          <div class="margin-top" [style.visibility]="(enable2FAFeature == false) ? 'hidden' : 'visible' ">
            <input type="radio" name="two_auth_option" (change)="changeTwoAuthOption($event)"
              [disabled]="siteInformation[0]?.enable_two_factor_auth === 0"
              [checked]="siteInformation[0]?.two_factor_auth_option === 'email'" value="email">
              <label for="html">Email</label>
              <input type="radio" name="two_auth_option" (change)="changeTwoAuthOption($event)"
              [disabled]="siteInformation[0]?.enable_two_factor_auth === 0"
              [checked]="siteInformation[0]?.two_factor_auth_option === 'sms'" value="sms">
              <label for="css">SMS</label>
          </div>
        </div>

        <br />

        <ng-container *ngIf="!!enableInstantLogin">
          <div class="input-container">
            <input [type]=" isPasswordVisible ? 'text' : 'password' " class="form-control" [value]="instantLoginToken" readonly
              placeholder="No Token Generated" style="font-size: 0.7em; padding: 0 0.5em; text-align: left;" />
            <span class="eye-icon" (click)="togglePasswordVisibility()">
              <i [ngClass]="isPasswordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
            </span>
            <button type="button" class="btn btn-primary float-right" (click)="clickGenInstantLoginToken()"
              style="width: inherit; font-size: 0.9em; margin-top: 0.5em;">
              Generate Instant Login Token
            </button>
          </div>
        </ng-container>

        <div class="row pt-3 px-3" *ngIf="istdateUpdated == 1" style="font-size: 14px;">
          {{ tdateSuccessMsg }}
        </div>
        <div class="row pt-3 px-3" *ngIf="istdateError == 1" style="font-size: 14px;">
          {{ tdateErrorMsg }}
        </div>
        <div class="row pt-3 px-3" *ngIf="issdateUpdated == 1" style="font-size: 14px;">
          {{ sdateSuccessMsg }}
        </div>
        <div class="row pt-3 px-3" *ngIf="issdateError == 1" style="font-size: 14px;">
          {{ sdateErrorMsg }}
        </div>
        <div class="dot1" *ngIf="isStatusUpdated == 1"></div>
        <div class="row pt-3 px-3" *ngIf="isStatusUpdated == 1" style="font-size: 14px;">
          {{ statSuccessMsg }}
        </div>
        <div class="dot1" *ngIf="isStatError == 1"></div>
        <div class="row pt-3 px-3" *ngIf="isStatusUpdated == 1" style="font-size: 14px;">
          {{ statErrorMsg }}
        </div>

        <div class="confirm-form1" *ngIf="showTConfirmation === 1" style="
            position: absolute;
            top: 120px;
            background: white;
            border-color: grey;
            border-style: solid;
            z-index: 99;
          ">
          <form>
            <div class="row m-2">
              <h6 class="margin-left:auto;margin-right:auto;">
                Are you sure you want to extend the trial date?
              </h6>
            </div>
            <span>
              <button type="submit" class="form-control btn btn-primary float-right" (click)="updTdate(trialdate)">
                Yes
              </button>
              <button type="submit" class="form-control btn btn-dark float-right" style="background-color: #aaa;"
                (click)="cancelUpd()">
                Cancel
              </button>
            </span>
          </form>
        </div>

        <div class="confirm-form2" *ngIf="showSConfirmation == 1" style="
            position: absolute;
            top: 120px;
            background: white;
            border-color: grey;
            border-style: solid;
            z-index: 99;
          ">
          <form>
            <div class="row m-2">
              <div>
                Are you sure you want to extend the subscription date?
              </div>
            </div>
            <span>
              <button type="submit" class="form-control btn btn-primary float-right"
                (click)="updatedSubscriptionDate()">
                Yes
              </button>
              <button type="submit" class="form-control btn btn-dark float-right" style="background-color: #aaa;"
                (click)="cancelUpd()">
                Cancel
              </button>
            </span>
          </form>
        </div>

        <div class="confirm-form3" *ngIf="showStatConfirmation == 1" style="
            position: absolute;
            top: 75px;
            background: white;
            border-color: grey;
            border-style: solid;
            z-index: 99;
          ">
          <form>
            <div class="row m-2">
              <h6 class="margin-left:auto;margin-right:auto;">
                Are you sure you want to change the company's status?
              </h6>
            </div>
            <span>
              <button type="submit" class="form-control btn btn-primary float-right" (click)="setStatus()">
                Yes
              </button>
              <button type="submit" class="form-control btn btn-dark float-right" style="background-color: #aaa;"
                (click)="cancelUpd()">
                Cancel
              </button>
            </span>
          </form>
        </div>


      </div>
    </div>


    <div *ngIf="currentUserInfo?.accesslevel == 'ADMIN'" class="card-body row" style="margin-top: -30px;">
      <div class="col-4" style="padding-left: 2rem; padding-right: 2rem;">
        <button type="button" class="btn btn-danger float-left" style="width: inherit;" (click)="showDeleteModal()">
          Delete company
        </button>
        <button type="button" class="btn btn-primary float-left" style="width: inherit; margin-top: 5px;"
          (click)="editCompany()">
          Edit company
        </button>
        <button type="button" class="btn btn-primary float-left"
          style="background: gray !important; width: inherit; margin-top: 5px;" (click)="showMspInfo()">
          MSP Notification Email
        </button>
      </div>
      <div class="col-8">
        <div class="row pt-3 px-3" style="top: 10px;"></div>
        <div class="row pt-3 px-3" style="top: 10px;"></div>
        <form role="form" class="mspemail" name="mspForm" [formGroup]="mspForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <div class="row">
              <input *ngIf="isShowMSPinfo == 1" type="text" formControlName="mspemail" class="form-control"
                style="width: 80% !important;" placeholder="MSP Email ( ex1@email.com;ex2@email.com )" id="mspemail"
                name="mspemail" autocomplete="email" [ngClass]="{ 'is-invalid': submitted && f.mspemail.errors }"
                autocomplete="email " />

              <button *ngIf="isShowMSPinfo == 1" type="submit" class="btn btn-primary" style="margin-left: 5px;">
                save
              </button>
              <span *ngIf="submitted && f.mspemail.errors" style="font-size: 15px; color: red;">Incorrect Format! Please
                follow (ex1&#64;email.com;ex2&#64;email.com)</span>
              <span *ngIf="isShowInstruction" style="font-size: 15px; color: blue;">Please add MSP email before enable
                notification(ex1&#64;email.com;ex2&#64;email.com)</span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <app-update-company *ngIf="isShowEditCompany" [companyInfo]="siteInformation[0]" [currentUserInfo]="currentUserInfo"
    [refreshCompany]="infoSiteEventEmitter" (infoSiteEmitter)="infoSiteEmitter($event)">
  </app-update-company>
</div>