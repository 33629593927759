<div class="customer-container">
  <div class="customer-left-section">
    <div *ngIf="currentUserInfo?.accesslevel=='ADMIN'" class="customer-left-add-company-btn"
      (click)="addCompanyClicked()">+ Add Company</div>
    <input type="text" id="search-input" (keyup)="myFunction()" placeholder="Search Company" />
    <div class="customer-table">
      <table id="company-table" class="table table-hover table-sm bg-white mt-1" style="padding-bottom: 100px;">
        <tbody>
          <tr scope="row" *ngFor="let allComp of allCompanyListData; let i = index">
            <td class="list-company" (click)="companySelected(allComp.company_id)"
              [class.btn-background]="highlightNum == i">
              <span class="table-span">{{ allComp.company_name }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="customer-mid-section being" *ngIf="beingDeleted">
    <div>This company is being deleted.....</div>
  </div>
  <div class="customer-mid-section" *ngIf="!beingDeleted">
    <app-info-site-details *ngIf="selectOption !== 4" [siteInformation]="selCompDtlData"
      [currentUserInfo]="currentUserInfo" [currentCompanyInfo]="currentCompanyLoggedIn" (infoSiteEventEmitter)="customerEventEmitter($event)"
      [isPlatformAdmin]="isPlatformAdmin" [isTenantAdmin]="isTenantAdmin" (mspButtonClicked)="mspButtonClicked($event)">
    </app-info-site-details>

    <div class="customer-mid-section-child-under-info" *ngIf="selectOption == 1">
      <app-info-details [siteInformation]="selCompDtlData"></app-info-details>
      <app-info-integrator *ngIf="shouldShowIntergrator" [siteInformation]="selCompDtlData"
        [currentUserInfo]="currentUserInfo" (refreshCompanyListChange)="customerEventEmitter($event)">
      </app-info-integrator>
    </div>

    <div class="customer-mid-section-child-under-info" *ngIf="selectOption == 2">
      <table class="table table-hover table-sm bg-white mt-1" style="padding-bottom: 100px; width: 100%;">
        <thead>
          <tr>
            <th colspan="2" scope="col">user</th>
            <th scope="col">email</th>
            <th scope="col">role</th>
            <th scope="col">active</th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr scope="row" *ngFor="let allUsers of allCompanyUserData; let i = index">
            <!-- <td colspan="2">{{ allUsers.first_name }} {{ allUsers.last_name }}</td> -->
            <td colspan="2">{{ allUsers.fullname }}</td>
            <td>{{ allUsers.email }}</td>
            <td>{{ allUsers.accesslevel }}</td>
            <td>{{ allUsers.active }}</td>
            <td *ngIf="currentUserInfo?.accesslevel=='ADMIN'" (click)="loginUser(allUsers.user_id, false)">view as</td>
            <td scope="col" *ngIf="isShowResendRegistration(allUsers.registration_code)">
              <app-btn-rounded typebtn="email" id="sendemail" (click)="sendEmailInvite(allUsers.email)">
              </app-btn-rounded>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="customer-mid-section-child-under-info" *ngIf="selectOption == 3">
      <div class="row" style="overflow: auto; height: 300px; width: 100%; padding-left: 2%;">
        <table class="table table-hover table-sm bg-white mt-1" style="padding-bottom: 100px;">
          <thead>
            <tr>
              <th colspan="2" scope="col"></th>
              <th scope="col">site name</th>
              <th scope="col">{{ dCollector.toLowerCase() }}</th>
              <th scope="col">version</th>
              <th scope="col">status</th>
            </tr>
          </thead>
          <tbody>
            <tr scope="row" *ngFor="let allCollector of allCompanyCollectorData; let i = index">
              <td colspan="2">
                <ng-template [ngIf]="allCollector.verified">
                  <img src="{{ collectorImg }}" style="height: 30px;" />
                </ng-template>
              </td>
              <td>{{ allCollector.site_name }}</td>
              <td>{{ allCollector.host_name }}</td>
              <td>{{ allCollector.version }}</td>
              <td>
                <ng-template [ngIf]="allCollector.status == 1">
                  <div class="status-green"></div>
                </ng-template>
                <ng-template [ngIf]="allCollector.status == -1">
                  <div class="status-red"></div>
                </ng-template>
                <ng-template [ngIf]="allCollector.status == 0">
                  <div class="status-grey"></div>
                </ng-template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="customer-section-four" *ngIf="selectOption == 4">
      <app-add-company (refreshCompanyEvent)="customerEventEmitter($event)" [isPlatformAdmin]="isPlatformAdmin">
      </app-add-company>
    </div>

    <app-document-center *ngIf="selectOption == 5" [companyId]="routeId" [currentUserInfo]="currentUserInfo">
    </app-document-center>

    <app-domotz-setup *ngIf="selectOption == 6" [companyId]="routeId" [currentUserInfo]="currentUserInfo">
    </app-domotz-setup>
  </div>

  <div class="customer-right-section" *ngIf="!beingDeleted">
    <app-btn-health (click)="fetchCompanyInfo()" label="Company info"></app-btn-health>
    <app-btn-health (click)="fetchCompanyUserList()" label="Users"></app-btn-health>
    <app-btn-health (click)="fetchCompanyCollectorList()" label="{{ dCollector }}"></app-btn-health>
    <app-btn-health (click)="openDocumentCenter()" label="Document Center"></app-btn-health>
    <app-btn-health *ngIf="isShowMSPDomotzAppBtn" (click)="openMSPDomotz()" label="MSP Domotz"></app-btn-health>
  </div>
</div>