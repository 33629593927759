<div class="filter-ctr">
  <table class="table table-borderless">
    <thead>
      <tr>
        <th scope="col">Company</th>
        <th scope="col">First name</th>
        <th scope="col">Last name</th>
        <th scope="col">Email</th>
        <th scope="col">Start date</th>
        <th scope="col">{{ label === 'inactive' ? 'Access Type' : 'End date' }}</th>
        <th scope="col" *ngIf="label !== 'inactive'">Term(Month)</th>
        <th scope="col" *ngIf="label !== 'inactive'">Days Remaining</th>
        <th scope="col" class="clickable">
          <span title="Total Video Devices">D</span>/<span title="Total Huddle Rooms">H</span>/<span
            title="Total VIP Users"
            >U</span
          >/<span title="Total Audio devices">A</span>/
          <span title="Total IoT devies">I</span>/
          <span title="Total IoT Spaces">S</span>
          
          <!-- <div class="hover-ctr">
            <div class="hover-child">
              VIDEO DEVICE/ AUDIO DEVICE/ HUDDLE ROOMS/ VIP USERS
            </div>
          </div> -->
        </th>
        <th scope="col">Sites</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of newData">
        <td>{{ item.company_name }}</td>
        <td>{{ item.first_name }}</td>
        <td>{{ item.last_name }}</td>
        <td>{{ item.email }}</td>
        <td>{{ item.start_date }}</td>
        <td>{{ label === 'inactive' ? item.status : item.end_date }}</td>
        <td *ngIf="label !== 'inactive'">{{ item.term }}</td>
        <td *ngIf="label !== 'inactive'">{{ item.days_remaining }}</td>
        <td>{{ checkIfDomotzIsEnabled(item.DHUAI, item) }}</td>
        <td>{{ item.sites }}</td>
      </tr>
    </tbody>
  </table>
</div>
